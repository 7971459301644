.div_calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
}
iframe {
  width: 80%;
  height: 600;
}

a {
  margin-top: 20px;
  margin-bottom: 20px;
}

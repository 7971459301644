.navbar-toggler{
    width: 20px; 
    height: 20px; 
    position: relative;
    transition: .5s ease-in-out;  
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus{
    outline: none !important; 
    box-shadow: none !important;
    border: none !important; 
}

.navbar-toggler span{
    margin: 0; 
    padding: 0; 
}

.toggler-icon{
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #EB455F; 
    border-radius: 1px; 
    opacity: 1;
    left: 0; 
    transform: rotate(0deg); 
    transition: .25s ease-in-out; 
}

.middle-bar{
    margin-top: 0px; 
}

/* when nav is clicked */ 

.navbar-toggler .top-bar{
    margin-top: 0px; 
    transform: rotate(135deg); 
}

.navbar-toggler .middle-bar{
    opacity: 0;
    filter: alpha(opacity=0)
}

.navbar-toggler .bottom-bar{
    margin-top: 0px; 
    transform: rotate(-135deg);
}

/* when nav is collapsed */ 
.navbar-toggler.collapsed .top-bar{
    margin-top: -20px; 
    transform: rotate(0deg); 
}

.navbar-toggler.collapsed .middle-bar{
    opacity: 1; 
    filter: alpha(opacity=100); 
}

.navbar-toggler.collapsed .bottom-bar{
    margin-top: 20px; 
    transform: rotate(0deg); 
}

#navbarNav {
    transition: height 0.5s ease;
}

    
@keyframes slideIn {
    0% {
        transform: translateY(1rem); 
        opacity: 0; 
    }
    100% {
        transform: translateY(0rem); 
        opacity: 1; 
    }
}


.slideIn{
    -webkit-animation-name: slideIn;
    animation-name: slideIn; 
    animation-duration: 1.0s; 
    animation-fill-mode: both; 
}
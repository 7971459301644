section {
  height: 50%;
  width: 100%;
}

.center-cropped {
  height: 500px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

#secondary_cover_img {
  width: 100;
  height: 100;
}

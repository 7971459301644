.div_registration {
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  text-decoration: none !important;
}

iframe {
  width: 100%;
  height: 400px;
}

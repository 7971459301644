.mandatory_disclosure {
  display: flex;
  align-items: center;
  flex-direction: column;
}
h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}
table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
}

table thead tr {
  background-color: #009879;
  color: white;
  text-align: start;
  font-weight: bold;
}

table th,
table td {
  padding: 12px 15px;
  text-align: left;
}
